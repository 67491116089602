import React, {Fragment} from 'react';
import './index.style.less';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/src/simplebar.css';
import AppVerticalMenu from '../components/AppVerticalNav';
import MainSidebar from '../components/MainSidebar';

const AppSidebar = ({isCollapsed}) => {
  return (
    <MainSidebar
      className={'mini-sidebar-toggle'}
      collapsible
      breakpoint='xl'
      collapsedWidth='0'
      collapsed={isCollapsed}>
      <Fragment>
        <div className={isCollapsed ? 'logo-mini-sidebar' : 'logo-sidebar'}>
          <img
            src={
              isCollapsed
                ? '/assets/logo/logo-mini.svg'
                : '/assets/logo/logo.png'
            }
            alt='logo'
          />
        </div>
      </Fragment>
      <SimpleBarReact
        className={
          isCollapsed
            ? 'app-mini-sidebar-scrollbar'
            : 'app-max-sidebar-scrollbar'
        }>
        <AppVerticalMenu />
      </SimpleBarReact>
    </MainSidebar>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
};
