import {Fragment, useState} from 'react';
import clsx from 'clsx';
import {Avatar, Dropdown, List} from 'antd';
import './index.style.less';
import IntlMessages from '../../../../utility/IntlMessages';
import {FiUser} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {setVisibleLogout} from 'src/domain/app/redux/auth/auth-slice';
import {useTheme} from 'src/domain/utility/AppProvider/ThemeProvider';
import {getDisabledLinkInSidebarMenu} from 'src/domain/app/redux/auth/auth-selectors';

const UserInfo = () => {
  const {themeMode}: any = useTheme();
  const dispatch = useDispatch();
  const disabledLinkInSidebarMenu = useSelector(getDisabledLinkInSidebarMenu);
  const [openDropdown, setOpenDropdown] = useState(false);

  const menu = (
    <List className='dropdown-list'>
      <List.Item onClick={() => dispatch(setVisibleLogout(true))}>
        <List.Item.Meta title={<IntlMessages id='common.logout' />} />
      </List.Item>
    </List>
  );

  return (
    <Fragment>
      <div
        className={clsx('cr-user-info', {
          light: themeMode === 'light',
        })}
        id='app-header-user-info'>
        <Dropdown
          dropdownRender={() => (disabledLinkInSidebarMenu ? <></> : menu)}
          open={disabledLinkInSidebarMenu ? false : openDropdown}
          onOpenChange={(open) => setOpenDropdown(open)}
          trigger={['click']}
          placement='bottomRight'
          overlayStyle={{
            zIndex: 1052,
            minWidth: 150,
          }}>
          <a className='cr-user-info-inner ant-dropdown-link'>
            <Avatar size={35} icon={<FiUser />} className='justify-center' />
          </a>
        </Dropdown>
      </div>
    </Fragment>
  );
};

export default UserInfo;
