import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {io} from 'socket.io-client';
import {getIsAuthenticated} from 'src/domain/app/redux/auth/auth-selectors';
import {setNewNotification} from 'src/domain/app/redux/notifications/notification-slice';
import {remoteSocketIoUrl} from 'src/shared/constants/AppConst';
import MainUtils from 'src/shared/utils/main';
import urlJoin from 'url-join';
import {getCountNotificationsUnread} from 'src/domain/app/redux/notifications/notification-selectors';
import {NotificationTypeValue} from 'src/shared/constants/AppEnums';
import {setNewNotificationForBooking} from 'src/domain/app/redux/booking/booking-slice';

export const useInitSocketIo = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const countNotificationsUnread = useSelector(getCountNotificationsUnread);
  const [checkJoin, setCheckJoin] = useState(false);
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    if (isAuthenticated) {
      setSocket(
        io(urlJoin(remoteSocketIoUrl!), {
          transports: ['websocket', 'polling'],
        }),
      );
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !checkJoin && socket) {
      socket?.on('connect', function () {
        socket.emit('checkRoom');
      });
      setCheckJoin(true);
    }
  }, [isAuthenticated, checkJoin, socket]);

  useEffect(() => {
    if (isAuthenticated && socket) {
      socket?.on('receiveNotification', (item: any) => {
        if (!MainUtils.isEmptyValue(item)) {
          switch (item?.Type) {
            case NotificationTypeValue.UpdateBookingShift:
              dispatch(
                setNewNotificationForBooking({
                  ...item?.Metadata,
                  id: item?.Metadata?.Id,
                }),
              );
              break;

            default:
              dispatch(setNewNotification(item));
          }
        }
      });
      socket?.on('disconnect', () => {
        socket.on('connect', function () {
          console.log('reconnect');
        });
      });
    }
  }, [isAuthenticated, socket, countNotificationsUnread]);
};
