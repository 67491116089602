export enum ManageRoles {
  List = 'READ_ROLES_LIST',
  Details = 'READ_ROLE_DETAILS',
  Create = 'CREATE_ROLE',
  Update = 'UPDATE_ROLE',
  Delete = 'DELETE_ROLE',
  Select = 'SELECT_ROLES',
}

export enum ManagePermissions {
  List = 'READ_PERMISSIONS_LIST',
  Details = 'READ_PERMISSION_DETAILS',
  Update = 'UPDATE_PERMISSION',
}

export enum ManageUser {
  List = 'READ_USERS_LIST',
  Create = 'CREATE_USER',
  CreateAdmin = 'CREATE_ADMIN',
  UpdateUserProfile = 'UPDATE_USER_PROFILE',
  UpdateUserRole = 'UPDATE_USER_ROLE',
  Profile = 'READ_USER_PROFILE',
  Delete = 'DELETE_USER',
  Select = 'SELECT_USERS',
  ActiveAndDeactive = 'ACTIVATE_/_DEACTIVATE_USER',
}

export enum ManageCaregivers {
  List = 'READ_CAREGIVERS_LIST',
  Details = 'READ_CAREGIVER_DETAILS',
  Create = 'CREATE_CAREGIVER',
  Update = 'UPDATE_CAREGIVER',
  Delete = 'DELETE_CAREGIVER',
  Select = 'SELECT_CAREGIVERS',
}

export enum ManageClients {
  List = 'READ_CLIENTS_LIST',
  Details = 'READ_CLIENT_DETAILS',
  Create = 'CREATE_CLIENT',
  Update = 'UPDATE_CLIENT',
  Delete = 'DELETE_CLIENT',
  Select = 'SELECT_CLIENTS',
}
export enum ManageCategories {
  List = 'READ_CARE_CATEGORIES_LIST',
  Details = 'READ_CARE_CATEGORY_DETAILS',
  Create = 'CREATE_CARE_CATEGORY',
  Update = 'UPDATE_CARE_CATEGORY',
  Delete = 'DELETE_CARE_CATEGORY',
  Select = 'SELECT_CARE_CATEGORIES',
}

export enum ManageSubcategory {
  List = 'READ_CARE_SUB_CATEGORIES_LIST',
  Details = 'READ_CARE_SUB_CATEGORY_DETAILS',
  Create = 'CREATE_CARE_SUB_CATEGORY',
  Update = 'UPDATE_CARE_SUB_CATEGORY',
  Delete = 'DELETE_CARE_SUB_CATEGORY',
  Select = 'SELECT_CARE_SUB_CATEGORIES',
}

export enum ManageNotifications {
  List = 'READ_NOTIFICATIONS_LIST',
  MakeNotificationIsRead = 'MAKE_NOTIFICATION_IS_READ',
}

export enum ManageServices {
  List = 'READ_CARE_SERVICES_LIST',
  Details = 'READ_CARE_SERVICE_DETAILS',
  Create = 'CREATE_CARE_SERVICE',
  Update = 'UPDATE_CARE_SERVICE',
  Delete = 'DELETE_CARE_SERVICE',
  Select = 'SELECT_CARE_SERVICES',
}

export enum ManageAdditionalServices {
  List = 'READ_ADDITIONAL_SERVICES_LIST',
  Details = 'READ_ADDITIONAL_SERVICE_DETAILS',
  Create = 'CREATE_ADDITIONAL_SERVICE',
  Update = 'UPDATE_ADDITIONAL_SERVICE',
  Delete = 'DELETE_ADDITIONAL_SERVICE',
  Select = 'SELECT_ADDITIONAL_SERVICES',
}

export enum ManageCareRecipients {
  List = 'READ_CLIENT_CARE_RECEPIENTS_LIST',
  Details = 'READ_CLIENT_CARE_RECEPIENT_DETAILS',
  Create = 'CREATE_CLIENT_CARE_RECEPIENT',
  Update = 'UPDATE_CLIENT_CARE_RECEPIENT',
  Delete = 'DELETE_CLIENT_CARE_RECEPIENT',
  Select = 'SELECT_CLIENT_CARE_RECEPIENTS',
}

export enum ManageBooking {
  List = 'READ_BOOKING_LIST',
  Details = 'READ_BOOKING_DETAILS',
  Create = 'CREATE_BOOKING',
  Update = 'UPDATE_BOOKING',
  Delete = 'DELETE_BOOKING',
  Select = 'SELECT_BOOKING',
  UpdateMarkupSettings = 'UPDATE_MARKUP_SETTINGS',
  UpdatePricingSettings = 'UPDATE_PRICING_SETTINGS',
}

export enum ManageCases {
  List = 'READ_CARE_CASES_LIST',
  Details = 'READ_CARE_CASE_DETAILS',
  Create = 'CREATE_CARE_CASE',
  Update = 'UPDATE_CARE_CASE',
  Delete = 'DELETE_CARE_CASE',
  Select = 'SELECT_CARE_CASES',
}

export enum ManageAgeGroup {
  List = 'READ_AGE_GROUPS_LIST',
  Details = 'READ_AGE_GROUP_DETAILS',
  Create = 'CREATE_AGE_GROUP',
  Update = 'UPDATE_AGE_GROUP',
  Delete = 'DELETE_AGE_GROUP',
  Select = 'SELECT_AGE_GROUPS',
}

export enum ManageLanguages {
  List = 'READ_LANGUAGES_LIST',
  Details = 'READ_LANGUAGE_DETAILS',
  Create = 'CREATE_LANGUAGE',
  Update = 'UPDATE_LANGUAGE',
  Delete = 'DELETE_LANGUAGE',
  Select = 'SELECT_LANGUAGES',
}

export enum ManageCertificates {
  List = 'READ_CERTIFICATES_LIST',
  Details = 'READ_CERTIFICATE_DETAILS',
  Create = 'CREATE_CERTIFICATE',
  Update = 'UPDATE_CERTIFICATE',
  Delete = 'DELETE_CERTIFICATE',
  Select = 'SELECT_CERTIFICATES',
}

export enum ManageBookingPayment {
  List = 'READ_BOOKING_PAYMENT_LIST',
  Details = 'READ_BOOKING_PAYMENT_DETAILS',
  Create = 'CREATE_BOOKING_PAYMENT',
  Update = 'UPDATE_BOOKING_PAYMENT',
  Delete = 'DELETE_BOOKING_PAYMENT',
}

export enum ManageDailyShifts {
  List = 'READ_DAILY_BOOKING_SHIFT_LIST',
  Update = 'UPDATE_DAILY_BOOKING_SHIFT',
}

export enum ManageBookingShiftFees {
  List = 'READ_BOOKING_SHIFT_FEES',
  Update = 'UPDATE_BOOKING_SHIFT_FEES',
  UpdateBookingMarkup = 'UPDATE_BOOKING_MARKUP',
}
