import {createSlice} from '@reduxjs/toolkit';
import {PricingSettingsAllDetailsDto} from 'src/shared/services/pricing-settings/dto/pricing-settings-all-details-dto';

interface PricingSettingsInitialState {
  pricingSettingsAllList?: PricingSettingsAllDetailsDto;
}

const initialState: PricingSettingsInitialState = {
  pricingSettingsAllList: undefined,
};

const pricingSettingsSlice = createSlice({
  name: 'pricingSettings',
  initialState: initialState,
  reducers: {
    setPricingSettingsAllList: (state, action) => {
      state.pricingSettingsAllList = action.payload;
    },
  },
});

export const {setPricingSettingsAllList} = pricingSettingsSlice.actions;

export default pricingSettingsSlice.reducer;
