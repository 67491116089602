import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import './index.style.less';
import {AppContentView} from '../../../index';
import AppFooter from '../components/AppFooter';
import AppScrollbar from '../../AppScrollbar';
import clsx from 'clsx';
import {
  FooterType,
  HidFooterInPages,
} from '../../../../shared/constants/AppEnums';
import {
  useLayout,
  useLayoutActions,
} from '../../../utility/AppProvider/LayoutProvider';
import Logout from '../../../../pages/auth/logout';
import {useLocation} from 'react-router-dom';

const MiniSidebarToggle = () => {
  const {pathname} = useLocation();
  const {footer, footerType}: any = useLayout();
  const {setFooter}: any = useLayoutActions();
  const [isCollapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (
      HidFooterInPages?.filter((item) => pathname.includes(item)) &&
      HidFooterInPages?.filter((item) => pathname.includes(item))?.length > 0
    ) {
      setFooter(false);
    } else setFooter(true);
  }, [pathname]);

  const onToggleSidebar = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <Layout
      className={clsx('app-layout-mini-sidebar', {
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}>
      <AppSidebar isCollapsed={isCollapsed} />
      <Layout className='app-layout-mini-sidebar-main'>
        <AppHeader
          isCollapsed={isCollapsed}
          onToggleSidebar={onToggleSidebar}
        />
        <AppScrollbar className='main-mini-sidebar-scrollbar'>
          <AppContentView />
          {/* <AppFooter /> */}
          <Logout />
        </AppScrollbar>
      </Layout>
    </Layout>
  );
};

export default MiniSidebarToggle;
